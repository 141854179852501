import React from "react"
import Layout from "../components/layout"
// import "react-responsive-carousel/lib/styles/carousel.min.css"
import TechnicalData from "../components/product_tech_data"
import ProductDetail from "../components/product_detail"
import dumper_placer from "../../public/static/images/products/dumper_placer.jpg"

function DumperPlacer() {

  const prodDetail = {
    "name": "Dumper Placer",
    "detail": {
      "about" : ["Dumper placer is also known as skip loader which is used fo carrying the different size of garbage containers or bins from one place to another. These are being manufactured with high strength, reliability, superior quality & utilization for its end use.", "Upholstery cleaner, carpet cleaning machines, foam generator machine, carpet blower, steam cleaner."],
      "advantages": [
        "Carrying waste material containers of different sizes.",
        "It is a unique, highly productive, easy operational and low maintenance, hygienic system to help in keeping a city clean.",
        "Better waste handling equipment vehicle in its range of products.",
        "Heavy duty cylinders to carry heavy containers safely in very less time.",
        "Optimum loading & through heavy duty unloading hydraulic ability hoses, pumping system and safety devices like load holding valve, pressure & safety valve etc.",
        "Hydraulically arrangement for lifting system with the help of control lever for minimize the human efforts & time.",
        "Arrangement for stabilizing of vehicle with stabilizers for better strength & rigidity at the same time of heavy operation.",
        "Suitable for municipalities, building developments as well as individual."
      ]
    },
    "tech_data": {
      "column1": [
        "Code",
        "Chassis",
        "Sheet Material",
        "Thickness",
        "Lifting Capacity (Cum)",
        "Hydraulic Cylinder",
        "Hydraulic Pump",
        "Hydraulic Hoses",
        "Power Take off",
        "Hydraulic Tank",
        "Lifting System"
      ],
      "column2": [
        "IEDP-02, 2 Ton, 6 Ton, 7 Ton, 9 Ton",
        "12 Ton, 16 Ton or can be Customized Mild",
        "Steel (IS:2062)",
        "3 mm (4,5,6) Etc",
        "(2.5) (3.5) (4.5) (6.5) Etc, 2/4 Nos. for Container Lifting & Customize size",
        "2 Nos. for Stabilizers (Customized) 30-80 LPM",
        "Gear Pump / Vane Pump 40-80 LPM",
        "High Pressure Hoses",
        "Suitable Power Transfer from vehicle PTO.",
        "40-80 Ltres. or Customize According to Capacity",
        "Hydraulic operated Cylinder & Control Lever."
      ]
    }
  }

  return (
    <Layout className="semi-automatic">
      <ProductDetail name={prodDetail.name} detail={prodDetail.detail} image={dumper_placer} />

      {prodDetail.tech_data !== undefined ? <TechnicalData techData={prodDetail.tech_data} /> : <></>}
    </Layout>
  )
}

export default DumperPlacer
